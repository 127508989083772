<template>
  <div class="container">
    <h3>Liebe Gäste,</h3>
    <p>
      nach einer 10-jährigen Probezeit wollen wir heiraten und mit euch diesen
      besonderen Tag feiern.
    </p>
    <p>
      Nach der kirchlichen Trauung in Allensbach und dem anschließend
      Sektempfang möchten wir mit euch im Naturfreundehaus Radolfzell
      weiterfeiern.
    </p>
    <p>
      Bitte gebt uns bis zum <b>15.03.2025 </b>
      <router-link :to="'/Rückmeldung'">Rückmeldung</router-link>, ob ihr dabei
      seid.
    </p>
  </div>
</template>

<script>
import { RouterLink } from "vue-router";
export default {
  name: "EinladungsView",
  components: {
    RouterLink,
  },
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  min-height: 100vh;
}
</style>
